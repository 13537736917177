<template>
  <PaymentStepper :steps="steps" title="Pago Móvil" @close="close">
    <template v-slot:image>
      <div style="min-height: 8vh">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-4"
        >
          <rect width="64" height="64" rx="8" fill="#F9F8F9" />
          <rect
            x="21.0908"
            y="12"
            width="21.8182"
            height="40"
            rx="1.28751"
            fill="#000421"
          />
          <path
            d="M22.8809 15.2721C22.8809 14.5311 23.4619 13.9304 24.1786 13.9304H39.7514C40.4681 13.9304 41.0492 14.5311 41.0492 15.2721V48.7313C41.0492 49.4723 40.4681 50.073 39.7514 50.073H24.1786C23.4619 50.073 22.8809 49.4723 22.8809 48.7313V15.2721Z"
            fill="#A7ACD3"
          />
          <rect
            x="25.394"
            y="12.1685"
            width="13.2207"
            height="3.43815"
            rx="0.689736"
            fill="#000421"
          />
          <path
            d="M26.5435 35.6906V26.5435H29.9475C30.4397 26.5435 30.8862 26.6458 31.2869 26.8505C31.6877 27.0509 32.0034 27.3384 32.2343 27.713C32.4695 28.0876 32.5871 28.5275 32.5871 29.0328C32.5871 29.2375 32.5588 29.4488 32.5022 29.6666C32.4499 29.8844 32.3584 30.0847 32.2278 30.2677C32.1015 30.4506 31.9272 30.59 31.7051 30.6858C31.9926 30.7555 32.2234 30.8862 32.3976 31.0778C32.5719 31.2651 32.7047 31.4829 32.7962 31.7312C32.8877 31.9751 32.9487 32.2256 32.9791 32.4826C33.0096 32.7396 33.0249 32.9726 33.0249 33.1817C33.0249 33.6521 32.9029 34.0768 32.659 34.4558C32.4151 34.8347 32.0862 35.1353 31.6724 35.3574C31.263 35.5796 30.81 35.6906 30.3134 35.6906H26.5435ZM28.3468 34.1552H30.1239C30.3635 34.1552 30.5813 34.0986 30.7773 33.9853C30.9733 33.8677 31.1301 33.7087 31.2477 33.5084C31.3653 33.3037 31.4241 33.0728 31.4241 32.8158C31.4241 32.598 31.3653 32.3911 31.2477 32.1951C31.1301 31.9991 30.9733 31.8401 30.7773 31.7181C30.5813 31.5918 30.3635 31.5287 30.1239 31.5287H28.3468V34.1552ZM28.3468 30.0259H29.7384C30.0259 30.0259 30.272 29.9323 30.4767 29.745C30.6815 29.5577 30.7838 29.3159 30.7838 29.0197C30.7838 28.7148 30.6815 28.484 30.4767 28.3272C30.272 28.166 30.0259 28.0854 29.7384 28.0854H28.3468V30.0259Z"
            fill="white"
          />
          <path
            d="M35.5757 35.8082C35.2403 35.7821 34.918 35.7124 34.6087 35.5992C34.2994 35.4816 34.0228 35.3247 33.7789 35.1287C33.5393 34.9284 33.352 34.6888 33.217 34.41L34.7328 33.7567C34.7807 33.8263 34.8526 33.9026 34.9484 33.9853C35.0443 34.0637 35.1597 34.1334 35.2947 34.1944C35.4341 34.2554 35.5953 34.2859 35.7782 34.2859C35.9133 34.2859 36.0439 34.2706 36.1702 34.2401C36.2966 34.2053 36.3989 34.1509 36.4773 34.0768C36.5557 33.9984 36.5949 33.896 36.5949 33.7697C36.5949 33.6303 36.5448 33.5236 36.4447 33.4496C36.3445 33.3755 36.2225 33.3211 36.0788 33.2862C35.9394 33.247 35.8044 33.2144 35.6737 33.1882C35.273 33.1185 34.894 32.9944 34.5368 32.8158C34.184 32.6329 33.8965 32.3976 33.6744 32.1102C33.4522 31.8227 33.3412 31.4786 33.3412 31.0778C33.3412 30.6379 33.4566 30.259 33.6874 29.941C33.9183 29.6186 34.2188 29.3704 34.5891 29.1961C34.9593 29.0219 35.3601 28.9348 35.7913 28.9348C36.3009 28.9348 36.7735 29.0415 37.2091 29.2549C37.6447 29.4684 37.9953 29.7689 38.261 30.1566L36.8367 30.9994C36.7801 30.9167 36.706 30.8383 36.6145 30.7642C36.5274 30.6858 36.4251 30.6205 36.3075 30.5682C36.1942 30.5116 36.0722 30.4789 35.9416 30.4702C35.7717 30.4571 35.6171 30.468 35.4777 30.5029C35.3383 30.5334 35.2272 30.5922 35.1445 30.6793C35.066 30.7664 35.0268 30.884 35.0268 31.0321C35.0268 31.1758 35.09 31.2826 35.2163 31.3523C35.3426 31.4176 35.4886 31.4677 35.6541 31.5025C35.8196 31.533 35.9655 31.57 36.0918 31.6136C36.4708 31.7356 36.8258 31.8924 37.1568 32.084C37.4922 32.2713 37.7623 32.5022 37.967 32.7766C38.1717 33.051 38.2676 33.3733 38.2545 33.7436C38.2545 34.1748 38.126 34.5538 37.869 34.8805C37.612 35.2028 37.2788 35.4489 36.8693 35.6188C36.4643 35.7843 36.033 35.8474 35.5757 35.8082Z"
            fill="white"
          />
        </svg>
      </div>
    </template>
  </PaymentStepper>
</template>

<script>
import PaymentStepper from "../PaymentStepper.vue";
import AmountStep from "../AmountStep.vue";
import PagoMovilStep2 from "./PagoMovilStep2.vue";
import ResultStep from "../ResultStep.vue";

export default {
  components: {
    PaymentStepper,
    AmountStep,
    PagoMovilStep2,
    ResultStep,
  },
  data() {
    return {
      paymentData: {
        amount: 0,
        success: false,
        PaymentTitle: "Pago Móvil",
        dataStep: 2,
      },
      steps: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setAmount(amount) {
      this.amount = amount;
      this.steps[1].data.amount = amount;
    },
  },
  created() {
    this.steps = [
      {
        component: AmountStep,
        data: this.paymentData,
      },
      {
        component: PagoMovilStep2,
        data: this.paymentData,
      },
      {
        component: ResultStep,
        data: this.paymentData,
      },
    ];
  },
};
</script>
