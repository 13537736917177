var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-between",staticStyle:{"min-height":"68vh !important"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"pt-8 pb-8",attrs:{"elevation":"0"}},[_c('div',{staticClass:"text--primary flex-grow-1"},[_c('p',[_vm._v(" Envia tu comprobante de pago e información relacionada para procesar la transacción ")]),_c('v-divider',{staticClass:"my-4"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.ES ? 'referencia' : 'reference',"rules":"required|max:200","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.ES ? 'Referencia' : 'Reference',"dense":"","outlined":"","error-messages":errors[0] && errors[0],"clearable":""},model:{value:(_vm.manualTopUpForm.details.reference),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm.details, "reference", $$v)},expression:"manualTopUpForm.details.reference"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":_vm.ES ? 'fecha de pago' : 'payment date',"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePickerInput',{attrs:{"label":_vm.ES ? 'Fecha de pago' : 'Payment date',"placeholder":_vm.ES ? 'Ingrese su Fecha de pago' : 'Place your payment date',"outlined":"","dense":"","max":_vm.maxDay,"error-messages":errors[0] && errors[0]},model:{value:(_vm.manualTopUpForm.details.date),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm.details, "date", $$v)},expression:"manualTopUpForm.details.date"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":_vm.ES ? 'correo a enviar factura' : 'email to send invoice',"rules":"required|email|max:200","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.ES ? 'Correo a enviar factura' : 'Email to send invoice',"dense":"","outlined":"","error-messages":errors[0] && errors[0],"clearable":""},model:{value:(_vm.manualTopUpForm.details.email),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm.details, "email", $$v)},expression:"manualTopUpForm.details.email"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-2 d-flex justify-center align-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.ES ? 'comprobante de pago' : 'payment receipt',"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{ref:"file",attrs:{"show-size":"","truncate-length":_vm.$vuetify.breakpoint.mdAndUp ? 50 : 10,"label":_vm.ES
                    ? 'Adjuntar comprobante de pago'
                    : 'Attach your payment voucher',"error-messages":errors[0] && errors[0],"accept":"image/png, image/jpg, image/jpeg","dense":"","outlined":""},on:{"change":function($event){return _vm.previewImage()}},model:{value:(_vm.manualTopUpForm.details.paymentImage),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm.details, "paymentImage", $$v)},expression:"manualTopUpForm.details.paymentImage"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":'¿Desea factura personalizada?',"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0] && errors[0],"label":'¿Desea factura personalizada?',"dense":"","row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("¿Desea factura personalizada?"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.manualTopUpForm.customInvoice),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm, "customInvoice", $$v)},expression:"manualTopUpForm.customInvoice"}},[_c('v-radio',{attrs:{"label":'Sí',"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("Sí"))+" ")])]},proxy:true}],null,true)}),_c('v-radio',{staticClass:"text--primary",attrs:{"label":"No","value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":'¿Esta recarga corresponde a una proforma?',"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors[0] && errors[0],"label":'¿Esta recarga corresponde a una proforma?',"dense":"","row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("¿Esta recarga corresponde a una proforma?"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.manualTopUpForm.proforma),callback:function ($$v) {_vm.$set(_vm.manualTopUpForm, "proforma", $$v)},expression:"manualTopUpForm.proforma"}},[_c('v-radio',{attrs:{"label":'Sí',"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("Sí"))+" ")])]},proxy:true}],null,true)}),_c('v-radio',{attrs:{"label":"No","value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(_vm.$t("No"))+" ")])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"button-group"},[_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","outlined":""},on:{"click":function($event){return _vm.$emit('prev')}}},[_vm._v(" Volver ")]),_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","color":"primary","disabled":invalid},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(" Continuar ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }