<template>
  <PaymentStepper :steps="steps" title="Pago Móvil" @close="close">
    <template v-slot:image>
      <div style="min-height: 9vh">
        <v-img
          class="mr-4"
          v-if="data.icon_file_url"
          :src="data.icon_file_url"
        />
      </div>
    </template>
  </PaymentStepper>
</template>

<script>
import PaymentStepper from "../PaymentStepper.vue";
import AmountStep from "../AmountStep.vue";
import ManualPaymentStep2 from "./ManualPaymentStep2.vue";
import ManualPaymentStep3 from "./ManualPaymentStep3.vue";
import ResultStep from "../ResultStep.vue";

export default {
  components: {
    PaymentStepper,
    AmountStep,
    ManualPaymentStep2,
    ManualPaymentStep3,
    ResultStep,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentData: {
        amount: 0,
        success: false,
        PaymentTitle: this.data.name,
        dataStep: Number(this.data.currency_code) === 2 ? 3 : 2,
      },
      steps: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setAmount(amount) {
      this.amount = amount;
      this.steps[1].data.amount = amount;
    },
  },
  created() {
    this.steps = [];

    if (Number(this.data.currency_code) === 2) {
      this.steps.push({
        component: AmountStep,
        data: { ...this.data, ...this.paymentData },
      });
    }

    this.steps.push(
      {
        component: ManualPaymentStep2,
        data: { ...this.data, ...this.paymentData },
      },
      {
        component: ManualPaymentStep3,
        data: { ...this.data, ...this.paymentData },
      },
      {
        component: ResultStep,
        data: { ...this.data, ...this.paymentData },
      }
    );
  },
};
</script>
