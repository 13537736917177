var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-between",staticStyle:{"min-height":"67vh !important"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],staticClass:"pa-2",attrs:{"label":"Cantidad a agregar","prefix":"$","outlined":"","dense":"","rules":[
      function (v) { return !!v || _vm.$t('Amount is required'); },
      function (v) {
        var numericValue =
          typeof v === 'number'
            ? v
            : parseFloat(this$1.data.amount.replace(/,/g, ''));
        return numericValue > 0 || _vm.$t('Amount must be greater than 0');
      } ]},model:{value:(_vm.data.amount),callback:function ($$v) {_vm.$set(_vm.data, "amount", $$v)},expression:"data.amount"}}),_c('div',{staticClass:"button-group"},[_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","outlined":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Volver ")]),_c('v-btn',{staticStyle:{"width":"40%"},attrs:{"rounded":"","color":"primary","disabled":_vm.handleDisabled},on:{"click":_vm.handleNext}},[_vm._v(" Continuar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }