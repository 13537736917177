<template>
  <div
    class="d-flex flex-column justify-between"
    style="min-height: 68vh !important"
  >
    <div class="text--primary flex-grow-1">
      <p class="text--primary text-center text-xl font-weight-bold">
        Realiza el pago a:
      </p>
      <v-row class="bg-light-gray my-1">
        <v-col cols="5" class="pa-1 px-4"> Titular de la cuenta </v-col>
        <v-col cols="7" class="text-end pa-1 font-weight-bold">
          {{ bank.accountHolder }}
          <span class="ml-2">
            <v-tooltip top v-model="showTooltip.showTooltipAccountHolder">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  @click="
                    handleCopyClipboard(
                      bank.accountHolder,
                      'showTooltipAccountHolder'
                    )
                  "
                  small
                >
                  <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                </v-btn>
              </template>
              <span>¡Titular de la cuenta copiado!</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <v-row class="bg-light-gray my-1">
        <v-col cols="5" class="pa-1 px-4">Correo electrónico </v-col>
        <v-col cols="7" class="text-end pa-1 font-weight-bold">
          {{ bank.email }}
          <span class="ml-2">
            <v-tooltip top v-model="showTooltip.showTooltipEmailHolder">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  @click="
                    handleCopyClipboard(bank.email, 'showTooltipEmailHolder')
                  "
                  small
                >
                  <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                </v-btn>
              </template>
              <span>¡Correo electrónico copiado!</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>

      <v-row class="bg-light-gray my-1">
        <v-col cols="5" class="pa-1 px-4">Número de memo </v-col>
        <v-col cols="7" class="text-end pa-1 font-weight-bold">
          {{ bank.memoNumber }}
          <span class="ml-2">
            <v-tooltip top v-model="showTooltip.showTooltipMemoNumber">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  @click="
                    handleCopyClipboard(bank.email, 'showTooltipMemoNumber')
                  "
                  small
                >
                  <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                </v-btn>
              </template>
              <span>¡Número de memo copiado!</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <p class="text-xl font-weight-bold">
        Coloca los datos del zelle realizado
      </p>

      <ValidationProvider
        name="fecha de pago"
        rules="required"
        v-slot="{ errors }"
      >
        <DatePickerInput
          label="Fecha de pago"
          placeholder="Ingrese su Fecha de pago"
          v-model="zelleForm.date"
          outlined
          dense
          :max="maxDay"
          :error-messages="errors[0] && errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        name="número de memo"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          label="Número de memo"
          v-model="zelleForm.memo"
          outlined
          dense
          :error-messages="errors[0] && errors[0]"
        >
        </v-text-field>
      </ValidationProvider>

      <!-- <ValidationProvider
        name="Monto enviado"
        rules="required"
        v-slot="{ errors }"
      > -->
      <v-text-field
        label="Monto enviado"
        v-model.lazy="zelleForm.amount"
        v-money="money"
        prefix="$"
        outlined
        dense
      >
      </v-text-field>
      <!-- </ValidationProvider> -->

      <ValidationProvider
        name="propietario zelle"
        rules="required|min:2|max:100"
        v-slot="{ errors }"
      >
        <v-text-field
          label="Propietario zelle"
          v-model="zelleForm.ownerInput"
          outlined
          dense
          :error-messages="errors[0] && errors[0]"
        >
        </v-text-field>
      </ValidationProvider>

      <p class="text--blue blue-border py-3 mt-3">
        <v-icon style="color: #16b1ff !important">{{
          icons.mdiInformationOutline
        }}</v-icon>
        <span class="pl-2">
          El propietario zelle es la persona o entidad que realizó el pago.
        </span>
      </p>

      <v-divider class="my-4"></v-divider>
      <ValidationProvider
        :name="'¿Desea factura personalizada?'"
        rules="required"
        v-slot="{ errors }"
        slim
      >
        <v-radio-group
          v-model="zelleForm.customInvoice"
          :error-messages="errors[0] && errors[0]"
          :label="'¿Desea factura personalizada?'"
          dense
          row
        >
          <template v-slot:label>
            <div class="text--primary font-weight-bold">
              {{ $t("¿Desea factura personalizada?") }}
            </div>
          </template>
          <v-radio :label="'Sí'" :value="true">
            <template v-slot:label>
              <div class="text--primary">
                {{ $t("Sí") }}
              </div>
            </template>
          </v-radio>
          <v-radio class="text--primary" label="No" :value="false">
            <template v-slot:label>
              <div class="text--primary">
                {{ $t("No") }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </ValidationProvider>
      <ValidationProvider
        :name="'¿Esta recarga corresponde a una proforma?'"
        rules="required"
        v-slot="{ errors }"
        slim
      >
        <v-radio-group
          :error-messages="errors[0] && errors[0]"
          :label="'¿Esta recarga corresponde a una proforma?'"
          dense
          row
          v-model="zelleForm.proforma"
        >
          <template v-slot:label>
            <div class="text--primary font-weight-bold">
              {{ $t("¿Esta recarga corresponde a una proforma?") }}
            </div>
          </template>
          <v-radio :label="'Sí'" :value="true">
            <template v-slot:label>
              <div class="text--primary">
                {{ $t("Sí") }}
              </div>
            </template>
          </v-radio>
          <v-radio label="No" :value="false">
            <template v-slot:label>
              <div class="text--primary">
                {{ $t("No") }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </ValidationProvider>
      <ValidationProvider
        v-if="zelleForm.proforma"
        :name="'Número de Proforma'"
        rules="required"
        v-slot="{ errors }"
        slim
      >
        <v-text-field
          v-model="zelleForm.proformaNumber"
          label="Proforma"
          outlined
          dense
          :error-messages="errors[0] && errors[0]"
        >
        </v-text-field>
      </ValidationProvider>
    </div>

    <!-- Botones para volver y continuar -->
    <div class="button-group">
      <v-btn rounded outlined style="width: 40%" @click="$emit('close')">
        Volver
      </v-btn>
      <v-btn
        rounded
        style="width: 40%"
        color="primary"
        :disabled="handleDisabled"
        @click="handleNextStep"
        :loading="loading"
      >
        Continuar
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiAlert,
  mdiContentCopy,
  mdiInformationOutline,
} from "@mdi/js";
import { copyTextToClipboard } from "@/mixins/copyToClipboardMixin";
import DatePickerInput from "@/components/DatePickerInput";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";

export default {
  name: "ZelleStep1",
  mixins: [copyTextToClipboard],
  components: {
    DatePickerInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      amount: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      bank: {
        email: "pagosriderycorp@xpsolutions.co",
        accountHolder: "Express Payment Solutions LLC",
        memoNumber: "RIDE-XXXXXX",
      },
      icons: {
        mdiMagnify,
        mdiAlert,
        mdiContentCopy,
        mdiInformationOutline,
      },
      showTooltip: {
        showTooltipAccountHolder: false,
        showTooltipEmailHolder: false,
        showTooltipMemoNumber: false,
      },
      zelleForm: {
        date: "",
        memo: "",
        ownerInput: "",
        customInvoice: undefined,
        proforma: undefined,
        proformaNumber: "",
        value: 0,
      },
      loading: false,
    };
  },
  methods: {
    formatCurrency() {
      // Format amount to currency
      this.amount = parseFloat(this.amount).toFixed(2);
    },
    handleCopyClipboard(value, tooltip = null) {
      this.copyTextToClipboard(value);
      if (tooltip && !this.showTooltip[tooltip]) {
        this.showTooltip[tooltip] = true;
        setTimeout(() => {
          this.showTooltip[tooltip] = false;
        }, 2000);
      }
    },
    async handleNextStep() {
      try {
        this.loading = true;
        const {
          amount,
          ownerInput,
          date,
          memo,
          customInvoice,
          proforma,
          proformaNumber,
        } = this.zelleForm;
        const params = {
          date: date,
          amount: Number(amount.replace(/,/g, '')),
          memo: memo,
          ownerInput: ownerInput,
          customInvoice: !!customInvoice,
          proforma: proforma ? proformaNumber : "",
        };
        const response = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/add-zelle`,
          params
        );
        this.data.success = response.data?.success;
      } catch (error) {
        console.log(error);
        this.data.success = false;
      } finally {
        this.loading = false;
        this.$emit("next");
      }
    },
  },
  computed: {
    maxDay() {
      let today = new Date();
      return this.$moment(today).add(-4, "hours").toISOString();
    },
    ...mapState("auth", ["unique_id"]),
    handleDisabled() {
      return (
        !this.zelleForm.date ||
        !this.zelleForm.memo ||
        !this.zelleForm.ownerInput ||
        this.zelleForm.ownerInput.length < 2 ||
        !this.zelleForm.amount ||
        this.zelleForm.amount <= 0
      );
    },
  },
  mounted() {
    let baseMemo = `${this.unique_id}`;
    while (baseMemo.length < 6) {
      baseMemo = "0" + baseMemo;
    }
    this.bank.memoNumber = "RIDE-" + baseMemo;
    this.zelleForm.memo = this.bank.memoNumber;
  },
};
</script>

<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.bg-light-gray {
  background-color: #fafafa !important;
}

.blue-border {
  border: 1px solid #16b1ff;
  padding: 5px;
  border-radius: 4px;
}

.text--blue {
  color: #16b1ff;
}
</style>
