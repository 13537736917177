<template>
  <div
    class="d-flex flex-column justify-between"
    style="min-height: 68vh !important"
  >
    <ValidationObserver v-slot="{ invalid }">
      <v-card class="pt-8 pb-8" elevation="0" v-show="!loading">
        <div class="text--primary flex-grow-1">
          <p class="text--primary text-center text-xl font-weight-medium">
            Monto a pagar
          </p>
          <p
            v-if="currency_rate"
            class="text--green text-center text-3xl font-weight-bold"
          >
            {{
              computedPrice
                | currency({
                  symbol: "Bs ",
                  thousandsSeparator: ",",
                  fractionCount: 2,
                  fractionSeparator: ".",
                  symbolPosition: "front",
                  symbolSpacing: false,
                  avoidEmptyDecimals: undefined,
                })
            }}
          </p>

          <v-divider class="my-4"></v-divider>

          <p class="font-weight-bold text-center">Realiza el pago a:</p>

          <v-row class="bg-light-gray my-1">
            <v-col cols="4" class="pa-1 px-4"> Banco </v-col>
            <v-col cols="8" class="text-end pa-1 font-weight-bold">
              {{ bank.name }}
              <span class="ml-2">
                <v-tooltip top v-model="showTooltip.showTooltipNameNumber">
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      @click="
                        handleCopyClipboard(bank.name, 'showTooltipNameNumber')
                      "
                      small
                    >
                      <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                    </v-btn>
                  </template>
                  <span>¡Banco copiado!</span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <v-row class="bg-light-gray my-1">
            <v-col cols="4" class="pa-1 px-4"> RIF </v-col>
            <v-col cols="8" class="text-end pa-1 font-weight-bold">
              {{ bank.rif }}
              <span class="ml-2">
                <v-tooltip top v-model="showTooltip.showTooltipRIFHolder">
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      @click="
                        handleCopyClipboard(bank.rif, 'showTooltipRIFHolder')
                      "
                      small
                    >
                      <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                    </v-btn>
                  </template>
                  <span>¡RIF copiado!</span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <v-row class="bg-light-gray my-1">
            <v-col cols="5" class="pa-1 px-4"> Número de teléfono </v-col>
            <v-col cols="7" class="text-end pa-1 font-weight-bold">
              {{ bank.number }}
              <span class="ml-2">
                <v-tooltip top v-model="showTooltip.showTooltipNumberHolder">
                  <template v-slot:activator="{ attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      @click="
                        handleCopyClipboard(
                          bank.number,
                          'showTooltipNumberHolder'
                        )
                      "
                      small
                    >
                      <v-icon small>{{ icons.mdiContentCopy }}</v-icon>
                    </v-btn>
                  </template>
                  <span>¡Número de teléfono copiado!</span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>
          <p class="text-xl font-weight-bold">
            Coloca los datos del pago móvil realizado
          </p>

          <v-row dense class="">
            <v-col cols="12">
              <ValidationProvider
                name="Banco"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-autocomplete
                  v-model="pagoMovilForm.bank"
                  :items="bankList"
                  item-value="code"
                  :menu-props="{ contentClass: 'list-style' }"
                  dense
                  outlined
                  label="Banco"
                  :error-messages="errors[0] && errors[0]"
                  :filter="handleFilterAutocompleteBanks"
                >
                  <template v-slot:selection="{ item }">
                    <span
                      :style="
                        (item.code + item.name).length > 35 &&
                        'font-size: 0.6rem;'
                      "
                    >
                      {{ item.code }} - {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.code }} - {{ item.name }}
                  </template>
                  <!-- <template v-slot:append-outer>
                <div class="mr-4">
                  <v-tooltip top :max-width="200">
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        {{ icons.mdiInformationOutline }}
                      </v-icon>
                    </template>
                    <span>
                      Este campo es autocompletado
                      <br />
                      Puedes buscar bancos por:
                      <br />
                      <ul class="mt-2">
                        <li>Nombre</li>
                        <li>Código</li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
              </template> -->
                </v-autocomplete>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col cols="12" md="4">
              <ValidationProvider
                name="Operadora"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-select
                  :items="pagoMovilForm.phonePrefixList"
                  label="Operadora"
                  outlined
                  dense
                  v-model="pagoMovilForm.phonePrefix"
                  :error-messages="errors[0] && errors[0]"
                >
                </v-select>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="8">
              <ValidationProvider
                name="Teléfono"
                rules="required|min:7|max:7|numeric"
                v-slot="{ errors }"
                slim
              >
                <PhoneInput
                  label="Teléfono"
                  v-model="pagoMovilForm.phone"
                  dense
                  outlined
                  :long="false"
                  :error-messages="errors[0] && errors[0]"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col cols="12" md="6">
              <ValidationProvider
                name="Fecha de pago"
                rules="required"
                v-slot="{ errors }"
              >
                <DatePickerInput
                  label="Fecha de pago"
                  placeholder="Ingrese su Fecha de pago"
                  v-model="pagoMovilForm.date"
                  outlined
                  dense
                  :max="maxDay"
                  :error-messages="errors[0] && errors[0]"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider
                name="Últimos 4 dígitos de referencia"
                rules="required|min:4|max:4|numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Últimos 4 dígitos de referencia"
                  v-model="pagoMovilForm.digits"
                  outlined
                  dense
                  v-mask="'####'"
                  hint="últimos 4 dígitos"
                  persistent-hint
                  :error-messages="errors[0] && errors[0]"
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>
        </div>

        <!-- Botones para volver y continuar -->
        <div class="button-group">
          <v-btn rounded outlined style="width: 40%" @click="$emit('prev')">
            Volver
          </v-btn>
          <v-btn
            rounded
            style="width: 40%"
            color="primary"
            :disabled="invalid"
            @click="$emit('next')"
          >
            Continuar
          </v-btn>
        </div>
      </v-card>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiAlert,
  mdiContentCopy,
  mdiInformationOutline,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { copyTextToClipboard } from "@/mixins/copyToClipboardMixin";
import bankList from "@/api/bankList";
import PhoneInput from "@/components/PhoneInput";
import DatePickerInput from "@/components/DatePickerInput";
import { mask } from "vue-the-mask";

export default {
  name: "PagoMovilStep2",
  mixins: [copyTextToClipboard],
  directives: { mask },
  components: {
    PhoneInput,
    DatePickerInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currency_rate: null,
      icons: {
        mdiMagnify,
        mdiAlert,
        mdiContentCopy,
        mdiInformationOutline,
      },
      bank: {
        number: "04126509977",
        rif: "J-500969210",
        name: "0172 - Bancamiga",
      },

      showTooltip: {
        showTooltipNumberHolder: false,
        showTooltipRIFHolder: false,
        showTooltipNameNumber: false,
      },
      bankList: bankList,
      pagoMovilForm: {
        bank: "",
        phonePrefixList: ["0412", "0414", "0424", "0416", "0426"],
        phonePrefix: "",
        phone: "",
        date: "",
        digits: "",
        customInvoice: undefined,
        proforma: undefined,
        proformaNumber: "",
      },
      loading: false,
    };
  },
  methods: {
    handleCopyClipboard(value, tooltip = null) {
      this.copyTextToClipboard(value);
      if (tooltip && !this.showTooltip[tooltip]) {
        this.showTooltip[tooltip] = true;
        setTimeout(() => {
          this.showTooltip[tooltip] = false;
        }, 2000);
      }
    },
    handleFilterAutocompleteBanks(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const name = _item.name.toLowerCase();
      const code = _item.code.toLowerCase();

      return name.indexOf(searchText) > -1 || code.indexOf(searchText) > -1;
    },
  },
  computed: {
    maxDay() {
      let today = new Date();
      return this.$moment(today).add(-4, "hours").toISOString();
    },
    computedPrice() {
      return (
        parseFloat(this.data.amount.replace(/,/g, "")) * this.currency_rate
      );
    },
  },
  async beforeMount() {
    const { data } = await axios.get("/get_currency_rate");
    if (data.success) {
      this.currency_rate = data.currency_rate;
    } else {
      throw new Error("Error getting Currency Rate");
    }
  },
};
</script>

<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.text--green {
  color: #2bd4aa !important;
}
.bg-light-gray {
  background-color: #fafafa !important;
}
</style>
