<template>
  <div
    class="d-flex flex-column justify-between"
    style="min-height: 68vh !important"
  >
    <ValidationObserver v-slot="{ invalid }">
      <v-card class="pt-8 pb-8" elevation="0" v-show="!loading">
        <div class="text--primary flex-grow-1">
          <p>
            Envia tu comprobante de pago e información relacionada para procesar
            la transacción
          </p>
          <v-divider class="my-4"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <ValidationProvider
                :name="ES ? 'referencia' : 'reference'"
                rules="required|max:200"
                v-slot="{ errors }"
                slim
              >
                <v-text-field
                  :label="ES ? 'Referencia' : 'Reference'"
                  dense
                  outlined
                  v-model="manualTopUpForm.details.reference"
                  :error-messages="errors[0] && errors[0]"
                  clearable
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <ValidationProvider
                :name="ES ? 'fecha de pago' : 'payment date'"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <DatePickerInput
                  :label="ES ? 'Fecha de pago' : 'Payment date'"
                  :placeholder="
                    ES ? 'Ingrese su Fecha de pago' : 'Place your payment date'
                  "
                  v-model="manualTopUpForm.details.date"
                  outlined
                  dense
                  :max="maxDay"
                  :error-messages="errors[0] && errors[0]"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <ValidationProvider
                :name="ES ? 'correo a enviar factura' : 'email to send invoice'"
                rules="required|email|max:200"
                v-slot="{ errors }"
                slim
              >
                <v-text-field
                  :label="
                    ES ? 'Correo a enviar factura' : 'Email to send invoice'
                  "
                  dense
                  outlined
                  v-model="manualTopUpForm.details.email"
                  :error-messages="errors[0] && errors[0]"
                  clearable
                >
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="mt-2 d-flex justify-center align-center">
            <v-col cols="12" md="12">
              <ValidationProvider
                :name="ES ? 'comprobante de pago' : 'payment receipt'"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-file-input
                  v-model="manualTopUpForm.details.paymentImage"
                  show-size
                  :truncate-length="$vuetify.breakpoint.mdAndUp ? 50 : 10"
                  :label="
                    ES
                      ? 'Adjuntar comprobante de pago'
                      : 'Attach your payment voucher'
                  "
                  :error-messages="errors[0] && errors[0]"
                  accept="image/png, image/jpg, image/jpeg"
                  dense
                  outlined
                  @change="previewImage()"
                  ref="file"
                >
                </v-file-input>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <ValidationProvider
                :name="'¿Desea factura personalizada?'"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-radio-group
                  v-model="manualTopUpForm.customInvoice"
                  :error-messages="errors[0] && errors[0]"
                  :label="'¿Desea factura personalizada?'"
                  dense
                  row
                >
                  <template v-slot:label>
                    <div class="text--primary font-weight-bold">
                      {{ $t("¿Desea factura personalizada?") }}
                    </div>
                  </template>
                  <v-radio :label="'Sí'" :value="true">
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ $t("Sí") }}
                      </div>
                    </template>
                  </v-radio>
                  <v-radio class="text--primary" label="No" :value="false">
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ $t("No") }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <ValidationProvider
                :name="'¿Esta recarga corresponde a una proforma?'"
                rules="required"
                v-slot="{ errors }"
                slim
              >
                <v-radio-group
                  :error-messages="errors[0] && errors[0]"
                  :label="'¿Esta recarga corresponde a una proforma?'"
                  dense
                  row
                  v-model="manualTopUpForm.proforma"
                >
                  <template v-slot:label>
                    <div class="text--primary font-weight-bold">
                      {{ $t("¿Esta recarga corresponde a una proforma?") }}
                    </div>
                  </template>
                  <v-radio :label="'Sí'" :value="true">
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ $t("Sí") }}
                      </div>
                    </template>
                  </v-radio>
                  <v-radio label="No" :value="false">
                    <template v-slot:label>
                      <div class="text--primary">
                        {{ $t("No") }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>

        <!-- Botones para volver y continuar -->
        <div class="button-group">
          <v-btn rounded outlined style="width: 40%" @click="$emit('prev')">
            Volver
          </v-btn>
          <v-btn
            rounded
            style="width: 40%"
            color="primary"
            @click="$emit('next')"
            :disabled="invalid"
          >
            Continuar
          </v-btn>
        </div>
      </v-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DatePickerInput from "@/components/DatePickerInput";

export default {
  name: "ManualPaymentStep3",
  components: {
    DatePickerInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      manualTopUpForm: {
        details: {
          reference: "",
          date: "",
          email: "",
          paymentImage: null,
        },
        customInvoice: false,
        proforma: false,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState(["ES"]),
    maxDay() {
      return new Date().toISOString().split("T")[0];
    },
  },
  methods: {
    ...mapActions(["manualTopUp"]),

    previewImage(e) {
      if (!this.manualTopUpForm.details.paymentImage) {
        return;
      }

      let error = undefined;
      // nulo
      if (!this.manualTopUpForm.details.paymentImage.size) {
        this.manualTopUpForm.details.paymentImage = null;
        return;
      }

      // Menor a 5mb
      if (this.manualTopUpForm.details.paymentImage.size) {
        const fileSize =
          this.manualTopUpForm.details.paymentImage.size / 1024 / 1024; //mb
        if (fileSize > 1) {
          this.$dialog.notify.error(
            this.ES
              ? "<h3>El archivo debe ser menor a 1mb </h3>"
              : "<h3>The file must be lower than 1mb </h3>",
            {
              position: "top-right",
              timeout: 2000,
            }
          );
          error = true;
        }
      }

      // tipos de archivo jpg o png
      let types = ["image/jpg", "image/jpeg", "image/png"];

      if (!types.includes(this.manualTopUpForm.details.paymentImage.type)) {
        this.$dialog.notify.error(
          this.ES
            ? "<h3>El archivo debe ser jpg, jpeg o png </h3>"
            : "<h3>The file must be jpg, jpeg or png </h3>",
          {
            position: "top-right",
            timeout: 2000,
          }
        );
        error = true;
      }

      if (error) {
        this.$nextTick(() => {
          this.manualTopUpForm.details.paymentImage = null;
          this.url = null;
        });
      } else {
        this.url = URL.createObjectURL(
          this.manualTopUpForm.details.paymentImage
        );
      }
    },
  },
};
</script>
<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.text--green {
  color: #2bd4aa !important;
}
.bg-light-gray {
  background-color: #fafafa !important;
}
</style>
